'use strict';
import { Services } from "../services/services";
import { SupplementStatus } from "../supplement_status/supplement_status";

export class GlobalDroApi {
  constructor() {
    this.name = 'GlobalDROApi';
    this.services = new Services();
    const self = this;
    $('.search_tools .search .cta_api').click(function () {
      self.handleSearchEvent();
    });
  }

  handleSearchEvent() {
    $('.search_results').text('');
    $('.search_total').text('');

    var search_term = $('.search_input .search_text').val();


    var container = $('<div />');
    var totalItems = $('<div />');

    if (search_term == '' || search_term.length <= 2) {
      $(".error_container").text("Please enter a valid text for search. Ensure the text is greater than 2 characters");
      return;
    }

    $(".error_container").text('');
    let self = this;
      const userType = $('.role.js-fb-roles option:selected').val();
      const sportId = $('.sport.js-fb-sport').val();
      const nationOfPurchase = "AU";
      const languageId = "en"
        var data = {
          nationOfPurchaseId: nationOfPurchase,
          sportId: sportId,
          userTypeId: userType,
          languageId: languageId,
        };
        localStorage.setItem("headerItem", JSON.stringify(data));
        this.services.fetchAPI(data,`/search?searchterm=${search_term}`, "GET", (response) => {
          console.log(response, "Data");
          totalItems.append(`<div class = "total_items">Total Results: ${response.results.length}</div>`)
          response.results.forEach((option) => {
            container.append(`<a class="results_text" id =${option.id} data-option=${option} href="pages/brand_status.html?brand_type=${option.type}&&id=${option.id}"">
            <div class ="result_name">
            <p>${option.name}</p>
              ${option.alternateNames} 
            </div>
            <div class = "result_type">${option.type}</div>
            </a>`);
            console.log(option.name);
          });
        });


    $('.search_results').append(container);
    $('.search_total').append(totalItems);
  }


}
