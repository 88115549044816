'use strict';
import { Services } from "../services/services";
export class SupplementStatus {
  constructor() {
    this.name = 'supplement_status';


    this.services = new Services();
    const self = this;
    var results_wrapper = $('<div />');
    let brand_ingredient_container = $('<div />');
    
    let searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has('brand_type')){
      var type = searchParams.get('brand_type');
      var id = searchParams.get('id');
      this.loadData(type, id);
    }

  }


  loadData(data) {

    if (window.location.href.indexOf("brand_status") > -1) {
      var results_wrapper = $('<div />');
      let brand_ingredient_container = $('<div />');
      let data_type = new URLSearchParams(location.search);
      let type = data_type.get('brand_type');
      let id = data_type.get('id');
      const headerData = JSON.parse(localStorage.getItem("headerItem"));
      this.services.fetchAPI(headerData,`/${type}/${id}`, "GET", (response) => {
        results_wrapper.append(`<div class = "wrapper">
          <div class = "name">${response.name}</div>
          <div class = "static_wada_text">Status According to the WADA Prohibited List</div>
          <div class ="prohibition">
            <div class = "in_competition ${response.statuses[0].inCompStatus.statusKey}">
              <p class="title_competition">In Competition </p>
              ${response.statuses[0].inCompStatus.statusKey}
            </div>
            <div class = "out_competition ${response.statuses[0].outOfCompStatus.statusKey}">
             <p class= "title_competition">Out of Competition </p>
            ${response.statuses[0].outOfCompStatus.statusKey}
            </div>
          </div>
          <div class = "conditions">
          <h4>Conditions/Warning</h4>
          <hr />
          <p class = "warning_text">${response.notes[0].text}</p>
          </div>
          <div class = "WADA_classification">
            <h3> WADA Classifications</h3>
            <hr />
            ${response.classifications[0].description}
          </div>
         
         <div class = "brand_ingredients"></div>
         <div class ="search_details">
          <table>
            <tr>
              <th>Reference</th>
              <th>Nation of Purchase</th>
              <th>Sport</th>
              <th>Search Date</th>
            </tr>
            <tr>
              <td>${response.details.referenceNumber}</td>
              <td>${response.details.nationOfPurchase}</td>
              <td>${response.details.sport}</td>
              <td>${response.details.dateTimeStamp}</td>
            </tr>
          </table>
       </div>
        </div>`)
        if (type == "brand") {
          let ingredients_list = $('<div />')
          response.ingredients.forEach((element) => {
            console.log(element.name, "element_name");
            ingredients_list.append(`<div class = "ingredients_list">
                    <div class = "ingredient_name">
                    <p>${element.name}</p>
                    <p>Other names: ${element.alternateNames}</p>
                    </div>
                    <div class ="prohibition">
                    <div class = "in_competition ${response.statuses[0].inCompStatus.statusKey}">
                      ${element.statuses[0].inCompStatus.statusKey}
                    </div>
                    <div class = "out_competition ${response.statuses[0].outOfCompStatus.statusKey}">
                    ${element.statuses[0].outOfCompStatus.statusKey}
                    </div>
                  </div>
                  </div>`);
          });
          console.log("ingredient", ingredients_list);
          brand_ingredient_container.append(`
                <div class = "brand_ingredient_title">
                    <p>Status of this brand's individual active ingredients</p>
                </div>
                
                `);
          brand_ingredient_container.append(ingredients_list);

        }
        $('.results_container').append(results_wrapper);
        $('.brand_ingredients').append(brand_ingredient_container);

      });
    }

  }

}


