'use strict';
import { Authentication } from "../authentication/authentication";
import { Services } from "../services/services";

export  class ReferenceList {
  constructor() {
    this.name = 'reference-list';
    console.log('%s module', this.name.toLowerCase());
    this.services = new Services();
    this.loadSportsList();
  }
  
  loadSportsList(){
        let headerData = {
          languageId: "en",
        }
        this.services.fetchAPI(headerData,`/reference/lists?listids=sport&listids=usertype`, "GET", (response) => {
          $.each(response, function(index, item){
            if(item["id"] == "Sport"){
              $.each(item["items"], function(index, sport){
                $("select.sport.js-fb-sport").append($('<option>',{
                  value:sport.id,
                  text:sport.value
                }))
              })
            }
          })
          $.each(response, function(index, item){
            if(item["id"] == "UserType"){
              $.each(item["items"], function(index, type){
                $("select.role.js-fb-roles").append($('<option>',{
                  value:type.id,
                  text:type.value
                }))
              })
            }
          })
      
        })
  }

}
