'use strict';

export class Authentication {
  
  constructor() {
    console.log("Authentication running");
    this.name = 'authentication';
    console.log('%s module', this.name.toLowerCase());
    this.authenticate();
  }


  authenticate() {
    console.log("Number of times run");
    var self = this;
    let data = JSON.stringify({
      "username": "7AAD3954-D8BA-4CCD-B1F0-7372AD88958C7ED5B68B-6FA1-4C38-AB79-5EDBD5DCF2E80981A8FD-52FA-4376-BD11-752B998DCC41",
      "password": "CC1BC802-B854-4139-BDB7-0BF03333463B7CAEDF71-D94D-4DF0-8031-FB3582FAEDC58C7D5231-F846-48A0-A4BE-B9082C35F78D"
    });
    //$(".spinner").removeClass("hidden");
    this.authRequestRunniung = true;
    let authToken = $.ajax({
      url: "https://extapi-staging.globaldro.com/v5614562.1/user/authenticate",
      method: "post",
      //dataType: "jsonp",
      async: false,
      headers: {
        "Content-Type": "application/json"
      },
      data: data,
      success: function (response) {
        self.authToken = response.jwtToken;
        self.expiresUtc = response.expiresUtc;
        self.authRequestRunniung = false;
        localStorage.setItem("jwtToken", self.authToken);
        getTokenExpirationMinutes(self.expiresUtc);
      },
      failed: function (error) {
        self.authRequestRunniung = false;
      },
    });

    function getTokenExpirationMinutes(dateString){
      const utcExpiresAt = new Date(dateString);
      const currentUtcTime = new Date();
      console.log(currentUtcTime);
      const timeDifferenceMilliseconds = utcExpiresAt - currentUtcTime;
      const minutesToAdd = Math.floor(timeDifferenceMilliseconds / (1000 * 60));
      // Add the minutes
      const currentDate = new Date();
      var newMinutes = currentDate.getMinutes() + minutesToAdd;
      console.log(newMinutes, "newMinutes")
      if(newMinutes >= 60){
        var newHours = currentDate.getHours() + Math.floor(newMinutes / 60);
        var adjustedMinutes = newMinutes % 60;
        currentDate.setHours(newHours);
        currentDate.setMinutes(adjustedMinutes);
        localStorage.setItem("expiredTimeToken", currentDate);
      }
      else{
        currentDate.setMinutes(newMinutes) ;
        localStorage.setItem("expiredTimeToken", currentDate);
      }
   
    }
    return authToken;

  }

}
