'use strict';

import { Authentication } from "../authentication/authentication";

export class Services {

  constructor() {
    this.name = 'services';
    console.log('%s module', this.name.toLowerCase());
    this.url = "https://extapi-staging.globaldro.com/v5614562.1";
  
    var expiryTime =new Date(localStorage.getItem("expiredTimeToken"));
    console.log(expiryTime ,"EXPIRY TEAM" , new Date(), "CURrent Time");
 
    if(expiryTime == null || expiryTime < new Date()){
      console.log("expired token");
      this.auth = new Authentication();
      const self = this;
      if(!this.auth.authToken){
        this.auth.authenticate();
        console.log("Reauthenticate 2")
      }
    }
 
  }

 
  fetchAPI(headerData, path, typedata, callback) {
    //$(".spinner").removeClass("hidden");
    var token = localStorage.getItem("jwtToken");
    console.log(token);
    return $.ajax({
      url: this.url + path,
      method: typedata,
      //dataType: "jsonp",
      async: false,
      headers: {
        'Authorization': `Brearer ${token}`,
        "Content-Type": "application/json",
        ...headerData,
      },
      success: function (response) {
        console.log(response);
        localStorage.setItem("response", JSON.stringify(response));
        // $(".spinner").addClass("hidden");
        callback(response)
      },
      error: function (xhr,error) {
     console.log(error)
      },
    });
  }
}
