'use strict';

window.$ = jQuery;

import {ReferenceList} from '../_modules/reference-list/reference-list';
import { GlobalDroApi } from '../_modules/globaldroapi/GlobalDROApi';
import { SupplementStatus } from '../_modules/supplement_status/supplement_status';

$(() => {
    //new Authentication();
    // new Firebase();
    new ReferenceList();
    new GlobalDroApi();
    new SupplementStatus();
});
